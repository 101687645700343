import React from 'react';
import { ListItem }  from '@material-ui/core';
import Link from 'gatsby-link';

function InternalListItem(props) {
    const { title, link } = props;

    return link ? (
      <ListItem
        key={title}
        button
        component={({ children, ...props }) => (
            <Link
                {...props}
                to={link}
            >
                {title}
            </Link>
        )}
      />
    ) : (
      <ListItem
        key={title}
        //button
      >
        {title}
      </ListItem>
    )
}

export default InternalListItem;
