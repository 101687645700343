import React from 'react';
import { List } from '@material-ui/core';

import {
    ExternalListItem,
    InternalListItem
} from '../common';

function TopicListItem(props) {
    const { title, link, children } = props;

    switch(true) {
      case children && children.length && children.length > 0:
        return (
          <React.Fragment key={title}>
            <InternalListItem {...props}/>
            <List
                component="div"
                disablePadding
                style={{ paddingLeft: '30px' }}
            >
              {children.map(child =>(<TopicListItem key={child.title} {...child}/>))}
            </List>
          </React.Fragment>
        )
      case props.type === 'external':
        return (<ExternalListItem {...props}/>)
      default:
        return (<InternalListItem {...props}/>)
    }
}

export default TopicListItem;
