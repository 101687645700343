import React from 'react';
import { ListItem }  from '@material-ui/core';

function ExternalListItem(props) {
    const { title, link } = props;
    return (
        <ListItem
            key={title}
            button
            component="a"
            style={{ color: "#00a2e8", paddingLeft: '30px' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
        >
            {title}
        </ListItem>
    )
}

export default ExternalListItem;
