import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import PageMap from '../../PageMap';

import logo from '../../images/interaction-studio-logo-dev-197x35-retina-ready@2x.png';

import { SectionListItem } from './common';

const drawerWidth = 265;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#f0f0f0",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
});

function DesktopDrawerLeft(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        anchor="left"
        id="MobileDrawerLeft"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} style={{ background: '#bee6fa' }}>
          <img
            alt=""
            style={{
              paddingTop: '10px',
              paddingLeft: '20px',
              maxHeight: '50px',
              marginBottom: '-10px',
            }}
            src={logo}
          />
        </div>
        <Divider />
        <List>
          {PageMap.map(section => (
            <SectionListItem {...section} key={section.title}></SectionListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

DesktopDrawerLeft.defaultProps = {
  classes: {
    root: 'root',
    appBar: 'app-bar',
    drawer: 'drawer',
    toolbar: 'toolbar',
    content: 'content',
  },
};

DesktopDrawerLeft.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(DesktopDrawerLeft);
