import React from 'react';
import Hidden from '@material-ui/core/Hidden';

import DesktopDrawerLeft from './DesktopSidebar';
import MobileDrawerLeft from './MobileSidebar';

const Sidebar = ({ mobileState, mobileToggle }) => (
    <>
        <Hidden mdUp implementation="css">
            <MobileDrawerLeft
                mobileOpen={mobileState}
                handleMobileToggle={mobileToggle}
            />
        </Hidden>
        <Hidden only={['xs', 'sm']} implementation="css">
            <DesktopDrawerLeft />
        </Hidden>
    </>
);

export default Sidebar;
