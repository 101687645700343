import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import PageMap from '../../PageMap';

import {
    SectionListItem
} from './common';

const drawerWidth = {
    sm: '265px',
    xs: '80vw'
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.only('sm')]: {
        width: `calc(100% - ${drawerWidth.sm})`,
        marginLeft: drawerWidth.sm,
    },
    [theme.breakpoints.only('xs')]: {
        width: `calc(100% - ${drawerWidth.xs})`,
        marginLeft: drawerWidth.xs,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.only('sm')]: {
        width: drawerWidth.sm
    },
    [theme.breakpoints.only('xs')]: {
        width: drawerWidth.xs
    },
  },
  drawerPaper: {
    background: "#f0f0f0",
    [theme.breakpoints.only('sm')]: {
        width: drawerWidth.sm
    },
    [theme.breakpoints.only('xs')]: {
        width: drawerWidth.xs
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
      marginRight: 'auto',
      marginLeft: 0,
  }
});

function MobileDrawerLeft(props) {
    const { classes } = props;
    return (
        <div className={classes.root} >
            <CssBaseline />
            <Drawer
                variant="temporary"
                anchor="left"
                open={props.mobileOpen}
                id="MobileDrawerLeft"
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
            <IconButton
                onClick={props.handleMobileToggle}
                className={classes.closeMenuButton}
            >
                <CloseIcon />
            </IconButton>
            <Divider />
            <List>
                {PageMap.map(section => (
                    <SectionListItem
                        {...section}
                        key={section.title}
                    />
                ))}
            </List>
            </Drawer>
        </div>
    );
}

MobileDrawerLeft.defaultProps = {
  classes: {
    root: 'root',
    appBar: 'app-bar',
    drawer: 'drawer',
    toolbar: 'toolbar',
    content: 'content',
  },
};

MobileDrawerLeft.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(MobileDrawerLeft);
