import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.div`
  display: block;
  border-top: 1px solid #eee;

  footer {
    height: 2.5rem;
  }

`

const Footer = () => (
    <StyledFooter>
        <footer className="fixed-bottom">
            <p style={{ padding: '1em 3.5em 1.75em', visibility: 'hidden' }}>
                &copy; Evergage 2019
            </p>
        </footer>
    </StyledFooter>
)

export default Footer
