const pageMap = [
  {
    title: 'Developer Introduction',
    link: '/develop-overview',
    children: [
      {
        title: 'Role and Responsibilities',
        link: '/develop-overview/developer-role-responsibilities',
      },
    ],
  },
  {
    title: 'Data Modeling',
    link: '/data-model',
    children: [
      {
        title: 'Attributes',
        link: '/data-model/attributes',
      },
      {
        title: 'Catalog',
        link: '/data-model/catalog',
      },
      {
        title: 'Datasets',
        link: '/data-model/datasets',
      },
    ],
  },
  {
    title: 'Web Integration',
    link: '/web-integration',
    children: [
      {
        title: 'Plan a Web Integration',
        link: '/web-integration/web-integration-planning',
      },
      {
        title: 'Sitemap Development',
        link: '/web-integration/sitemap',
        children: [
          {
            title: 'Validate JavaScript Beacon Deployment',
            link: '/web-integration/sitemap/validate-beacon-deployment',
          },
          {
            title: 'Get Started with Sitemapping',
            link: '/web-integration/sitemap/site-map-getting-started',
          },
          {
            title: 'Best Practices for Data Capture',
            link: '/web-integration/sitemap/sitemap-data-capture',
          },
          {
            title: 'Sitemap Event Validation',
            link: '/web-integration/sitemap/sitemap-event-validation',
          },
          {
            title: 'Content Zones',
            link: '/web-integration/sitemap/contentzones',
          },
          {
            title: 'Page Types',
            link: '/web-integration/sitemap/pagetypes',
          },
          {
            title: 'Interaction Definitions',
            link: '/web-integration/sitemap/interaction-definitions',
          },
          {
            title: 'Item Actions',
            link: '/web-integration/sitemap/item-actions',
          },
          {
            title: 'Custom Events',
            link: '/web-integration/sitemap/custom-events',
          },
          {
            title: 'Comparison Shopping',
            link: '/web-integration/sitemap/comparison-shopping',
          },
          {
            title: 'Capturing User and Account Attributes',
            link: '/web-integration/sitemap/site-map-user-account-attributes',
          },
          {
            title: 'Sitemap Implementation',
            link: '/web-integration/sitemap/sitemap-implementation-notes',
          },
          {
            title: 'Sitemap Examples',
            link: '/web-integration/sitemap/examples',
            children: [
              {
                title: 'Financial Services',
                link: '/web-integration/sitemap/examples/financial-services',
              },
              {
                title: 'E-commerce',
                link: '/web-integration/sitemap/examples/ecommerce',
              },
              {
                title: 'B2B',
                link: '/web-integration/sitemap/examples/b2b',
              },
            ],
          },
        ],
      },
      {
        title: 'User Identity Mapping',
        link: '/web-integration/user-identity-mapping',
      },
      {
        title: 'Multisite Implementation Strategy',
        link: '/web-integration/multisite-strategy',
      },
      {
        title: 'Site Impact Approach',
        link: '/web-integration/site-impact-approach',
      },
    ],
  },
  {
    title: 'Event API',
    link: '/event-api',
    children: [
      {
        title: 'Event API Requests',
        link: '/event-api/event-api-specifications',
      },
      {
        title: 'Event API Responses',
        link: '/event-api/event-api-response',
      },
    ],
  },
  {
    title: 'Mobile Integration',
    link: '/mobile-integration',
    children: [
      {
        title: 'Mobile iOS SDK',
        link: 'https://developer.evergage.com/mobile-sdk/ios/latest/',
        type: 'external',
      },
      {
        title: 'Mobile Android SDK',
        link: 'https://developer.evergage.com/mobile-sdk/android/latest/',
        type: 'external',
      },
    ],
  },
  {
    title: 'Data Ingestion',
    link: '/data-ingestion',
    children: [],
  },
  {
    title: 'Campaign Development',
    link: '/campaign-development',
    children: [
      {
        title: 'Templates',
        link: '/campaign-development/web-templates',
        children: [
          {
            title: 'Get Started with Global Web Templates',
            link: '/campaign-development/web-templates/web-template-global-templates',
          },
          {
            title: 'Template Server TypeScript',
            link: '/campaign-development/web-templates/web-template-server-typescript',
          },
          {
            title: 'Template Client JavaScript',
            link: '/campaign-development/web-templates/web-template-client-javascript',
          },
          {
            title: 'Template Handlebars',
            link: '/campaign-development/web-templates/web-template-handlebars',
          },
          {
            title: 'Template CSS',
            link: '/campaign-development/web-templates/web-template-html-classes-and-css',
          },
          {
            title: 'Template Response Simulator',
            link: '/campaign-development/web-templates/template-response-simulator',
          },
          {
            title: 'Campaign Stats Tracking',
            link: '/campaign-development/web-templates/web-campaign-stats',
          },
          {
            title: 'Modify Global Templates',
            link: '/campaign-development/web-templates/modify-global-templates',
          },
        ],
      },
      {
        title: 'Web Campaigns & Templates',
        link: '/campaign-development/web-campaign-and-templates',
        children: [
          {
            title: 'Web Template Building Best Practices',
            link: '/campaign-development/web-templates/web-template-building-checklist',
            children: [
              {
                title: 'Template Display Utilities',
                link: '/campaign-development/web-templates/web-display-utilities',
              },
              {
                title: 'Template Flicker Defender',
                link: '/campaign-development/web-templates/web-flicker-defender',
              },
            ],
          },
          {
            title: 'Templates Style Guide and Coding Conventions',
            link:
              '/campaign-development/web-templates/web-templates-style-guide-and-coding-conventions',
          },
          {
            title: 'Web Campaign Debugger',
            link: '/campaign-development/web-campaign-debugger',
          },
        ],
      },
      {
        title: 'Server Side Campaigns & Templates',
        link: '/campaign-development/server-side-campaigns-and-templates',
        children: [
          {
            title: 'Server Side Campaigns and Content Zones',
            link: '/campaign-development/server-side-campaigns-and-templates/contentzones',
          },
          {
            title: 'Multiple Server Side Campaign Responses in a Single Call',
            link: '/campaign-development/server-side-campaigns-and-templates/multiple-campaigns',
          },
          {
            title: 'Einstein Decisions - Server-Side Template',
            link: '/campaign-development/server-side-campaigns-and-templates/einstein-decisions',
          },
          {
            title: 'Sales/Service Cloud Connector - Next Best Recommendations Template',
            link:
              '/campaign-development/server-side-campaigns-and-templates/serverside-next-best-recommendations-template',
          },
          {
            title: 'Sales/Service Cloud Connector - Einstein Decisions Template',
            link:
              '/campaign-development/server-side-campaigns-and-templates/sales-service-cloud-connector-einstein-decisions',
          },
        ],
      },
      {
        title: 'Email Campaigns & Templates',
        link: '/campaign-development/email-campaigns-and-templates',
        children: [
          {
            title: 'Render Non-Western Characters in OTE Templates',
            link:
              '/campaign-development/email-campaigns-and-templates/render-non-western-characters',
          },
          {
            title: 'Capture an Open-Time Email Campaign URL',
            link:
              '/campaign-development/email-campaigns-and-templates/capture-open-time-email-campaign-url',
          },
          {
            title: 'Add Third Party Tracking Parameters to Open-Time Email Campaigns',
            link:
              '/campaign-development/email-campaigns-and-templates/third-party-tracking-for-email-campaigns',
          },
        ],
      },
      {
        title: 'Advanced Use-Case APIs',
        link: '/campaign-development/advanced-use-case-apis',
        children: [
          {
            title: 'IS Trends',
            link: '/campaign-development/advanced-use-case-apis/is-trends',
          },
          {
            title: 'Web Surveys',
            link: '/campaign-development/advanced-use-case-apis/web-surveys',
          },
        ],
      },
      {
        title: 'Campaign Template APIs',
        link: '/campaign-development/campaign-template-apis',
        children: [
          {
            title: 'Recommendations',
            link: '/campaign-development/campaign-template-apis/recommendations',
          },
          {
            title: 'Corvus (Contextual Bandit)',
            link: '/campaign-development/campaign-template-apis/corvus',
          },
        ],
      },
    ],
  },
  {
    title: 'Data Analytics',
    link: '/data-analytics',
    children: [
      {
        title: 'Data Warehouse',
        link: '/data-analytics/data-warehouse',
      },
      {
        title: 'Data Dictionary',
        link: '/data-analytics/data-dictionary',
      },
      {
        title: 'Scratch Schema',
        link: '/data-analytics/scratch-schema',
      },
    ],
  },
  {
    title: 'Gear Development',
    link: '/gears',
    children: [
      // {
      //   title: 'Gear Configuration',
      //   link: '/gears/config',
      // },
      {
        title: 'Metadata',
        link: '/gears/metadata',
      },
      {
        title: 'Core API Typedoc',
        link: 'http://evergage-gears-docs.s3-website-us-east-1.amazonaws.com/core',
        type: 'external',
      },
      {
        title: 'Testing API Typedoc',
        link: 'http://evergage-gears-docs.s3-website-us-east-1.amazonaws.com/test',
        type: 'external',
      },
      {
        title: 'Components',
        link: '/gears/components',
        children: [
          {
            title: 'Lifecycle',
            link: '/gears/components/lifecycle',
          },
          {
            title: 'OAuth',
            link: '/gears/components/oauth',
          },
          {
            title: 'Contextual Rules',
            link: '/gears/components/contextual-rule',
          },
        ],
      },
      {
        title: 'System Services',
        link: '/gears/system-services',
        children: [
          {
            title: 'Filesystem',
            link: '/gears/system-services/filesystem',
          },
          {
            title: 'HTTP',
            link: '/gears/system-services/http',
          },
        ],
      },
    ],
  },
  {
    title: 'Interface & Template Configuration',
    link: '/interface-config',
    children: [
      {
        title: 'Properties',
        children: [
          {
            title: 'Boolean',
            link: '/interface-config/properties/boolean',
          },
          {
            title: 'String',
            link: '/interface-config/properties/string',
          },
          {
            title: 'Number',
            link: '/interface-config/properties/number',
          },
          {
            title: 'Color',
            link: '/interface-config/properties/color',
          },
          {
            title: 'DateTime',
            link: '/interface-config/properties/datetime',
          },
          {
            title: 'Complex Object',
            link: '/interface-config/properties/complex',
          },
          {
            title: 'Select',
            link: '/interface-config/properties/select',
          },
          {
            title: 'Multi Select',
            link: '/interface-config/properties/multi-select',
          },
          // {
          //   title: 'Typed List',
          //   link: '/config/properties/typed-list'
          // }
        ],
      },
      {
        title: 'Arrays',
        link: '/interface-config/arrays',
      },
      {
        title: 'Decorators',
        link: '/interface-config/decorators',
      },
      {
        title: 'Static Values',
        link: '/interface-config/static-values',
      },
      {
        title: 'Imports & Exports',
        link: '/interface-config/import-export',
      },
    ],
  },
  {
    title: 'Additional APIs',
    link: '/additional-apis',
    children: [
      {
        title: 'User Look-Up & Deletion API',
        link: '/additional-apis/user-look-up-deletion-api',
      },
      {
        title: 'Account Export API',
        link: '/additional-apis/account-export-api',
      },
      {
        title: 'Principals API',
        link: '/additional-apis/principals-api',
      },
    ],
  },
];

export default pageMap;
