import React from 'react';
import {
    Collapse,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {
    ExpandLess,
    ExpandMore
} from '@material-ui/icons';

import Link from 'gatsby-link';

import { TopicListItem } from '../common';

function SectionListItem(props) {
    const [open, setOpen] = React.useState(typeof window !== 'undefined' && window.location.pathname.indexOf(props.link) >= 0);
    const { title, link, children } = props;

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <React.Fragment key={title}>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={title} style={{ color: "#00a2e8"}}/>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
              <ListItem
                button
                component={({children, ...props}) => (
                  <Link
                    {...props}
                    to={link}
                >
                    Overview
                  </Link>
                )}
              />
              {children ? children.map(child =>(<TopicListItem key={child.title} {...child}/>)) : (<></>)}
            </List>
        </Collapse>
      </React.Fragment>
    );
}

export default SectionListItem;
