import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Index } from 'elasticlunr';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
});

class SearchAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      results: [],
      anchorEl: null,
    };
    this.onChange = this.onChange.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.getSuggestedContent = this.getSuggestedContent.bind(this);
  }

  componentDidMount() {
    this.setState({
      anchorEl: document.getElementById('search-icon'),
    });
  }

  onChange(event) {
    this.search(event);
  }

  handlePopoverOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  getOrCreateIndex = () => {
    const { searchIndex } = this.props;
    return this.index ? this.index : Index.load(searchIndex); // Create an elastic lunr index and hydrate with graphql query results
  };

  search = evt => {
    const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {
          fields: {
            title: {
              boost: 5,
              bool: 'AND',
              expand: false
            },
            tags: {
              boost: 3,
              bool: 'OR',
              expand: true
            },
            content: {
              boost: 1,
              bool: 'AND',
              expand: true
            }
          },
          bool: 'OR',
          expand: true,
        })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    });
  };

  getSuggestedContent = (content, query) => {
    // console.log('getSuggestedContent for', { content }, { query });
    const index = content.search(query);
    const buffer = query.length + 60;
    if (index) {
      return content.substring(index - 1, index + buffer);
    }
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, results, query } = this.state;
    const open = Boolean(anchorEl);

    const popperRef = React.createRef;

    return (
      <>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon id="search-icon" />
          </div>
          <InputBase
            placeholder="Search…"
            onChange={this.onChange}
            value={query}
            autoFocus
            // autoFocus={true}
            onFocus={this.handlePopoverOpen}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        <Popper
          id="search-results"
          open={open}
          anchorEl={anchorEl}
          transition
          placement="top-start"
          disablePortal
          popperRef={popperRef}
          popperOptions={
            {
              onCreate: function(data) {
                // console.log('popperRef:', data);
                data.instance.scheduleUpdate();
              }
            }
          }
        >
          <Paper>
            <List>
              {results.splice(0,10).map(page => (
                <ListItem key={page.id} component={Link} to={`${page.path}`}>
                  {/* <Link to={`${page.path}`}>{page.title}</Link> */}
                  <ListItemText
                    primary={page.title}
                    secondary={this.getSuggestedContent(page.content, query)}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      </>
    );
  }
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  searchIndex: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchAppBar);
