// src/components/Chrome/index.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Sidebar from '../Sidebar';
import Footer from '../Footer';
import Header from '../Header';

const Container = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`;
const Content = styled.div`
  display: flex;
`;
const Main = styled.div`
  /* margin-left: 350px; */
  padding-top: 50px;
`;

const Chrome = ({ children, header }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    return (
        <>
            <Container>
                <Header
                    title={header.props.title}
                    mobileToggle={handleDrawerToggle}
                />
                <Content>
                    <Sidebar
                        mobileState={mobileOpen}
                        mobileToggle={handleDrawerToggle}
                    />
                    <Main>{children}</Main>
                </Content>
            </Container>
            <Footer />
        </>
    )
};
Chrome.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
};
export default Chrome;
